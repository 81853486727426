.gata{
    min-height: 100vh;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.join {
    min-height: 90%;
    max-height: 220vh;

    background-color: #FFF;
    justify-content: space-around;
display: flex;
padding-top: 111px;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: fit-content;
}

.left h2 {
    max-width: 287px;
    width: 100%;
    color: #0C2D1A;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 106%;
    /* 33.92px */
    text-transform: uppercase;
    margin-bottom: 14px;
}

.left h3 {
    color: #B88D00;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 106%;
    /* 38.16px */
    text-transform: uppercase;
    max-width: 559px;
    width: 100%;
    flex-shrink: 0;
    margin-bottom: 26px;
}

.left p {
    color: #000;
    text-align: center;
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    max-width: 483px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
}

.bright{
    position: absolute;
    right: 0;
}
.bleft{
    position: absolute;
    left: 0;
    bottom: 26px;
}
.getaccess{
    border-radius: 4.507px;
background: #0C2D1A;
display: flex;
width: 149px;
height: 48px;
justify-content: center;
align-items: center;
gap: 2.817px;
flex-shrink: 0;
color: #FFF;
text-align: center;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
cursor: pointer;
margin-top: 33px;
}
.right {
    display: flex;
    flex-direction: column;

}

.right h3 {
    color: #0C2D1A;
    font-family: "Plus Jakarta Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 106%;
    /* 38.16px */
    display: flex;
    max-width: 373px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    text-align: left;
    margin-bottom: 31px;
}

.paras{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}
.paras span{
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    flex-shrink: 0;

}
.paras p {
display: flex;
max-width: 406px;
width: 100%;
flex-direction: column;
justify-content: center;
color: #000;
font-family: Cabin;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
flex-direction: row;
gap: 11px;
}
.paras > p> div{
    text-align: left;
}
.paras img{
    width: 24px;
height: 24px;
}

.slide-in-div {
    position: absolute;
    bottom: -84%;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid #ccc;
    transition: bottom 0.3s ease-in-out;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
  height: 90%;
  flex-direction: column;
  align-items: center;
  border-radius: 32px 32px 0px 0px;
}
.slide-in-div.open {
    bottom:  -55px;
}


.popup {
    display: flex;
    justify-content: center;
    align-items: baseline;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  
  .popup-content {
    max-width: 823px;
    min-height: 448px;
    flex-shrink: 0;
    border: 8px solid var(--Container, #0C2D1A);
background: var(--White, #FEFEFE);
    text-align: center;
    position: relative;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    top: 117px;

  }
  .content2{
    gap: 60px;
  }
  .cross{
    display: flex;
    width: 94%;
    justify-content: flex-end;
    margin-top: 16px;
cursor: pointer;
  }
  .line{
background-color: #959595;
width: 1px;
height: 17px;
flex-shrink: 0;
  }
  .error-feild{
    width: 100%;
  }
  .error-feild1{
    width: 244px;
  }
  .error-feild,
  .error-feild1{
    display: flex;
    flex-direction: column;
    align-items: first baseline;
    
  }
  .error{
    color: #FF4242;
font-family: Cabin;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 19.2px */
letter-spacing: 0.454px;
  }


  
.form {
    max-width: 802px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    z-index: 1;
}

.name-email2 {
    display: flex;
    gap: 32px;
    width: 100%;
    justify-content: flex-end;
}

.name-email2 input {
    flex-shrink: 0;
    color: #3F3F3F;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 169.231% */
    border: none;
    border-radius: 4px;
}

.name2,
.email,
.number,
.type2,
.media {
    color: #3F3F3F;
    font-family: Cabin;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 169.231% */
    border-radius: 4px;
    border: 1px solid #000;
    padding-left: 10.5px;
    padding-right: 10.5px;
    padding-top: 5px;
    padding-bottom: 5px;
    gap: 4px;
    width: 95%;
    height: 20.6px !important;
}

.name2 {
    display: flex;
    align-items: center;
}

.email {
    display: flex;
    align-items: center;
}

.number {
    max-width: 244px;
    width: 95%;
    display: flex;
    align-items: center;

}

.form input,
.number input {
    color: #242424;
    font-family: Cabin;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 169.231% */
    border: none;
    width: 50%;
    flex-grow: 1;

}

.type2 {
    max-width: 526px;
    width: 100%;
    height: 32px;
    flex-shrink: 1;
    display: flex;
    align-items: center;
}

.number-type2 {
    display: flex;
    gap: 32px;
    width: 100%;
    justify-content: flex-end;
}

.media {
    width: 97%;
    display: flex;
    align-items: center;
}

.desc {
    width: 100%;
}

.desc textarea {
    border-radius: 6px;
    border: 1px solid #242424;
    background-color: #FEFEFE;
    display: flex;
    max-width: 783px;
    width: 100%;
    height: 101px;
    padding: 8px 17px;
    align-items: flex-start;
    gap: 8px;
    resize: none;
    padding-right: 0px;
    font-family: Cabin;

}

.submit {
    color: #FFF;
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 32px;
    background-color: #0C2D1A;
    display: inline-flex;
    height: 32px;
    padding: 0px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    margin-top: 32px;
    margin-bottom: 163px;
}
.submit:hover{
    cursor: pointer;
}
.slide-in-div h2{

text-align: center;
font-family: "Plus Jakarta Sans";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 106%; /* 42.4px */
display: flex;
max-width: 382px;
width: 100%;
height: 32px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
margin-top: 64px;
}
input:focus, textarea:focus, select:focus{
    outline: none;
}

@media (max-width:983px) {
    .join{
        flex-direction: column;
        gap: 24px;
        padding-top: 20px;
        align-items: center;
    }
    .right{
        padding-bottom: 80px;
        width: 95%;
        align-items: center;

    }
    .slide-in-div{
        bottom: -86%;
    }
    .left{
        gap: 8px;
        align-items: center;
    }
    .bleft{
        bottom: 58px;
    }
    .left p{
        width: 95%;
    }
    .form{
        width: 95%;
    }
}

@media (max-width:700px) {
    .form-container h2{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .number-type2,
    .name-email2{
        flex-direction: column;
    }
    .desc textarea,
    .name2,
    .email,
    .type2,
    .number{
        max-width: 97%;
        width: 100%;
    }
    .access{
        display: flex;
width: 149px;
height: 32px;
padding: 6.76px 32.11px;
justify-content: center;
align-items: center;
gap: 2.817px;
flex-shrink: 0;
    }
    .para{
        height: auto;
    }
}
@media (max-width:650px) {
    .benifit {
        flex-direction: column;
        margin-bottom: 53px;
        margin-top: 53px;
        gap: 32px;
    }

    .middle-img {
        display: none;
    }
    .form input{
        width: 50%;
    }
    .logo{
        right: 30px;
    }
}

@media (max-width:400px) {
    .beni {
        color: #0C2D1A;
        font-family: Plus Jakarta Sans;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 106%;
        /* 50.88px */
        display: flex;
        width: 252px;
        height: 142px;
        flex-direction: column;
        justify-content: center;
    }
    .black2, .gold {
        font-size: 20px;

    }
    .name2, .email, .number, .type2, .media {
        padding-right: 0px;
    }
    

}