.dashboard {
    width: 100%;
}

.charts {
    background: #DDEBE3;
    display: inline-flex;
    padding: 32px 2px 0px 0px;
    justify-content: center;
    align-items: center;
    gap: 17px;
    width: 100%;
    height: 339px;
    flex-wrap: wrap;
}

.revenue {
    border-radius: 8px;
    background: #FDFDFD;
    box-shadow: 1.30534px 1.30534px 2.61069px 0px rgba(0, 0, 0, 0.25);
    max-width: 171px;
    width: 100%;
    height: 241px;
    flex-shrink: 0;
    position: relative;

}

.growth {
    border-radius: 8px;
    background: #FDFDFD;
    box-shadow: 1.30534px 1.30534px 2.61069px 0px rgba(0, 0, 0, 0.25);
    max-width: 512px;
    width: 100%;
    height: 241px;
    flex-shrink: 0;
    position: relative;
}

.total-revenue {
    display: flex;
    align-items: center;
    gap: 19px;
    margin-top: 22px;
}

.total-revenue h3 {
    color: #176F02;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 13.053px;
    /* 100.411% */
    width: 58px;
    height: 22px;
    text-align: left;
}

.total-revenue div {
    background-color: #5AB581;
    width: 32px;
    height: 3px;
}

.total-current {
    display: flex;
    align-items: center;
    gap: 26px;
    margin-top: 12px;

}

.total-current h3 {
    color: #B88D00;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 13.053px;
    /* 100.411% */
    width: 51px;
    height: 22px;
    text-align: left;
}

.total-current div {
    background-color: #B88D00;
    width: 32px;
    height: 3px;
}

.pie-chart {
    height: 127px !important;
    width: 127px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;

}

.revenue-details {
    gap: 8px;
    position: absolute;
    right: 31px;
    top: 41px;
}

.rupee {
    color: #176F02;
    font-family: Inter;
    font-size: 20.885px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.053px;
    /* 62.5% */
}

.revenu {
    color: #5AB581;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.053px;
    /* 81.584% */
    width: 63px;
    height: 12px;
}

.current {
    color: var(--Yellow-Text, #B88D00);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.053px;
    /* 81.584% */
    width: 63px;
    height: 12px;
}

.growth-over {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 13.053px;
    /* 81.584% */
    /* max-width: 208px; */
    width: 84%;
    height: 11px;
    flex-shrink: 0;
    display: flex;
}

.line-chart {
    width: 83% !important;
    height: 188px !important;
}

.available-oppo {
    background: #4B4B4B;
    width: 100%;
    height: 65px;
    flex-shrink: 0;
}

.available-oppo h3 {
    color: var(--BG, #FEFEFE);
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    max-width: 998px;
    width: 95%;
    display: flex;
}

.oppotunities {
    width: 100%;
}

.oppotunity {
    min-height: 162px;

}

.eligibility {
    display: inline-flex;
    align-items: center;
    gap: 9px;
    max-width: 1006px;
    width: 100%;
    height: 24px;
    margin-bottom: 34px;
    flex-wrap: wrap;
}

.eligibility h3 {
    color: var(--Container, #0C2D1A);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.min-follower {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 155px;
    height: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #242424;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.category-req {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 173px;
    height: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: #242424;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bold1 {
    color: #242424;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.mobile-request {
    display: none;
}
.chart-stat{
    position: relative;

}
.indicator {

}
.noab{
    color: #959595;
    font-size: 25px;
    margin-top: 24px;
    font-weight: 300;
    text-transform: uppercase;

}
.noab1{
    color: #959595;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    position: absolute;
}
@media (max-width:1034px) {
    .request {
        display: none;
    }

    .mobile-request {
        display: flex;
        border-bottom: 1px solid #959595;
        background: #FDFDFD;
        /* max-width: 1280px; */
        width: 100%;
        min-height: 95px;
        flex-shrink: 0;
        gap: 80px;
        flex-wrap: wrap;
        transition: height 0.3s ease;
        row-gap: 5px;
        position: relative;
        justify-content: center;
        padding-bottom: 20px;

    }

    .mobile-heading {
        display: flex;
        background: #F9F9F9;
        width: 100%;
        height: 65px;
        flex-shrink: 0;
        justify-content: space-around;
    }

    .Campaignname {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .eligibility {
        width: 90%;
        margin-top: 24px;
        justify-content: flex-start;
        max-width: 635px;

    }
    .additional-content {
       
        max-width: 634px !important;
    }
   
}

@media (max-width:700px) {
   .charts{
    flex-direction: row;
    height: auto;
    padding-bottom: 24px;
   }
   .revenue{
    flex-direction: row;
    max-width: 512px;
    gap: 30px;
    width: 95%;
   }
   .growth{
    width: 95%;
   }
  
}

@media (max-width:400px) {
    .eligibility {
        margin-bottom: 66px;
        justify-content: center;
    }
}