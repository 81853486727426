.flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.cflex{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.App{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #FFF;
  position: relative;
}
h3{
  margin-top: 0;
  margin-bottom: 0;
}
p{
  margin-top: 0;
  margin-bottom: 0;
}
.App::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.App {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
a{
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}