.signin {
    background: rgba(254, 254, 254, 1);
    width: 100%;
    min-height: 85vh;
    max-height: 200vh;
    flex-wrap: wrap;
}

.signin-area {
    width: 40%;
}

.signin-box {
    border-radius: 8px;
    border: 1px solid #000;
    background: #FEFEFE;
    max-width: 410px;
    width: 100%;
    height: 357px;
    flex-shrink: 0;
}

.sign-head {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;
}

.sign-head h3 {
    color: var(--Main-Text, #242424);
    text-align: center;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 13.053px;
    /* 40.792% */
}

.sign-input {
    gap: 9px;
    width: 100%;
}

.sign-input input {
    color: #959595;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid #000;
    background: #FFF;
    display: flex;
    max-width: 263px;
    width: 90%;
    height: 32px;
    align-items: center;
    gap: 8px;
    padding-left: 9px;
}

.forget-pass {
    color: #959595;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 64%;
    display: flex;
}

.continue {
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    max-width: 274px;
    width: 90%;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    margin-top: 30px;
    cursor: pointer;
}

.forgot {
    gap: 0px;
}

.forgot h3 {
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.forgot p {
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    width: 339px;
    height: 51px;
    flex-direction: column;
    justify-content: center;
}
.bottombar{
    width: 100%;
    height: 52px;
    background-color: rgba(231, 233, 236, 1);
    ;
}
.btext{
    font-family: Raleway;
    font-size: 16px;
}
.btext a{
    font-family: Raleway;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    color: inherit; 
}
.yblob{
    background-image: url('C:\Users\91880\OneDrive\Desktop\Webminix projects\CDashboard\dashboard\src\media\leftb.png'), url('C:\Users\91880\OneDrive\Desktop\Webminix projects\CDashboard\dashboard\src\media\rightb.png');
    background-repeat: no-repeat;
    background-position: top left, right top;
    width: 100%;
}
.bblob{
    background-image: url('C:\Users\91880\OneDrive\Desktop\Webminix projects\CDashboard\dashboard\src\media\bleft.png'), url('C:\Users\91880\OneDrive\Desktop\Webminix projects\CDashboard\dashboard\src\media\bright.png');
    background-repeat: no-repeat;
    background-position: bottom left, bottom right;
    width: 100%;
}
.drawing{
    position: relative;
}
.welcomme{
    color:  #FEFEFE;
text-align: center;
font-family: Raleway;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 13.053px; /* 40.792% */
border: 1px solid #000;
background: #5AB581;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
display: inline-flex;
padding: 10px 33px;
justify-content: center;
align-items: center;
gap: 8px;
position: absolute;
top: 115px;
}
.cd{
    color:  #242424;
text-align: center;
leading-trim: both;
text-edge: cap;
font-family: Raleway;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 13.053px; /* 40.792% */
border: 1px solid #000;
background: #FEFEFE;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
display: inline-flex;
padding: 12px 14px;
justify-content: center;
align-items: center;
gap: 8px;
position: absolute;
}

@media (max-width:1000px) {
    .signin-area {
        width: 100%;
        margin-top: 32px;
    }

    .signin {
        gap: 100px;
    }
}

@media (max-width:616px) {

    .signin {
        flex-direction: column-reverse;
        gap: 0;
    }
    .drawing{
        margin-top: 24px;
    }
    .drawing img{
        width: 360px;
height: 229.953px;
flex-shrink: 0;
    }
    .signin-box{
        width: 95%;
    }
    .welcomme{
        top: 41px;
    }
    .cd{
        font-size: 18px;
    }


}
@media (max-width:381px) {
    .drawing img{
        width: 260px;
        height: 168.953px;
flex-shrink: 0;
    }
    .welcomme{
        top: 17px;
        font-size: 16px;
    }
    .cd{
        font-size: 15px;
    }
}