.status.orange {
    color: #FF783E;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
 }
  
  .status.red {
    color: #E30000; /* Or any other styles you wish to apply */
text-align: center;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
  
  .status.green {
    color: #176F02;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .upload{
    width: 89%;
    gap: 7px;
    justify-content: flex-start;
    position: relative;
  }
  .cut{
    position: absolute;
    left: 42%;
  }
  .upload input{
    border-radius: 8px;
    border: 0.5px solid #000;
    background: #F9F9F9;
    max-width: 358px;
    width: 100%;
height: 32px;
padding-left: 24px;
  }
  .upload-btn{
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
background: #242424;
display: flex;
width: 138px;
height: 32px;
justify-content: center;
align-items: center;
gap: 8px;
padding: 2px 50px;
cursor: pointer;

  }
  
.campaign-popup h3{
    max-width: 525px;;
    color: #000;
text-align: center;
font-family: Raleway;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 110% */
width: 100%;
gap: 7px;
justify-content: flex-start;

}
.error-msg{
  position: absolute;
  top: 38px;
  color: red;
}
.error-msg2{
  position: absolute;
  top: 33px;
  color: red;
}
@media (max-width:600px) {
  .upload-btn{
    width: 98px;
height: 32px;
padding: 0px 0px;
color: #FFF;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
}
@media (max-width:500px) {
 .upload{
  flex-direction: column;
  width: 80%;
  gap: 21px;
 }
 .upload-btn{
  width: 100%;
 }
}