.navbar {
    /* max-width: 1280px; */
    width: 100%;
    position: relative;
    z-index: 9999;
}

.logo {
    color: var(--Yellow, #B88D00);
    font-family: Playfair Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    gap: 7px;
    margin-left: 80px;
    display: flex;
    align-items: center;
    line-height: 15px;

}

.up {
    border-bottom: 1px solid #DEDEDE;
    background: #FEFEFE;
    /* max-width: 1280px; */
    width: 100%;
    height: 55px;
    flex-shrink: 0;
    justify-content: space-between;
}

.group {
    gap: 16px;
    margin-right: 80px;

}

.profile {
    gap: 12px;
}

.profile h3 {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
}
.profile img{
        width: 32px;
        height: 32px;
        border-radius: 50px;
        object-fit: cover;
}
.drop {
    display: flex;
    cursor: pointer;
    position: relative;
}
.dropdown-content{
position: absolute;
border-radius: 10px;
background: var(--Pinterest-White, #FFF);
box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
display: flex;
width: 296px;
padding: 10px;
flex-direction: column;
align-items: flex-start;
top: 38px;
left: -277px;
z-index: 2;
}
.dropdown-option{
border-radius: 10px;
display: flex;
height: 48px;
justify-content: space-between;
align-items: center;
align-self: stretch;
color: #000;
font-family: Raleway;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 150% */
cursor: pointer;
padding-left: 20px;
padding-right: 20px;

}
.dropdown-option:hover{
    border-radius: 10px;
background: #EFEFEF;
}
.down {
    background: #FEFEFE;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    /* max-width: 1280px; */
    width: 100%;
    height: 62px;
    gap: 48px;
}

.nav-item {
    display: flex;
align-items: center;
gap: 8px;
color: #3D4043;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 125% */
text-transform: uppercase;
cursor: pointer;
}
.nav-item h3{
    color: #3D4043;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 125% */
text-transform: uppercase;
}
.clicked{
    color: var(--Yellow, #B88D00);
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 125% */
text-transform: uppercase;
}
.clicked  h3{
    color: var(--Yellow, #B88D00);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    text-transform: uppercase;
}
.notification{
    cursor: pointer;
}


.notification-screen {
  position: absolute;
  top: -339px; /* Initially hide the notification screen */
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: -1;
  transition: top 0.3s ease-in-out;
  background: var(--Pinterest-White, #FFF);
box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
display: flex;
width: 100%;
height: 339px;
flex-direction: column;
align-items: flex-start;
}

.notification-content {
  max-height: 339px; /* Set maximum height for notification screen */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
  width: 100%;
}
.sample-notifications{
    width: 100%;
    margin-top: 12px;

}

.notification-item {
 
  border-radius: 10px;
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
width: 100%;
min-height: 73px;
}
.notification-item:hover{
    border-radius: 10px;
background: #EFEFEF;
}
.notificat{
    margin-left: 70px;
    align-items: baseline;

}
.notificat h3{
    color: var(--Primary-Text, #242424);
font-family: Raleway;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 150% */

}
.notificat p{
    color: var(--Hint, #737373);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    display: flex;
    text-align: left;
}

.view-all-button {
   width: 100%;
   justify-content: flex-end;
}
.viewall{
    color: var(--White, #FEFEFE);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 12px;
background: var(--Grey, #3F3F3F);
display: flex;
width: 116px;
height: 27px;
justify-content: center;
align-items: center;
gap: 8px;
margin-right: 69px;
cursor: pointer;

}

.full-height {
  top: 0; /* Show notification screen at full height */
  height: 84vh;
  max-height: none; /* Override max-height for full height */
  overflow-y: auto; /* Enable scrolling for full-height notification screen */
}
.notification-screen.open{
top: 117px;
}
.noti-overlay{
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: -2;

}
body.freeze-scroll {
    overflow: hidden;
  }
.notification-content.open{
    max-height: none;
}

.nav-item {
    /* Your styles for each navbar item */
    position: relative; /* Ensure the position is set to relative for proper positioning of the dot */
}

/* Dot image styles */
.dot {
    /* Your dot styles */
    position: absolute;
    bottom: -10px; /* Adjust the position of the dot based on your design */
    left: 50%; /* Position the dot in the middle of the navbar item */
    transform: translateX(-50%);
    width: 8px; /* Adjust the width and height of the dot as needed */
    height: 8px;
    border-radius: 50%; /* Make the dot round */
    background-color: #ff5733; /* Set the background color of the dot */
    /* Add animation or transition properties for animation effects */
}

@media (max-width:800px) {
    .notificat{
        margin-left: 36px;
    }
}
@media (max-width:670px) {
    .down{
        gap: 24px;
    }
}
@media (max-width:600px) {
   
    .nav-item h3{
        display: none;
    }
    .down{
        justify-content: space-around;
    }
    .logo{
        margin-left: 30px;
    }
    .group{
        margin-right: 24px;
    }
}
@media (max-width:400px) {
   
   .notification{
    display: none;
   }
   .profile h3{
    display: none;
   }
}