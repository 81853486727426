.help {
    border-radius: 8px;
    background: #F9F9F9;
    box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
    max-width: 967px;
    width: 95%;
    height: 435px;
    margin-top: 36px;
}

.help-head {
    gap: 8px;
    margin-bottom: 48px;
}

.help-head h3 {
    color: var(--Primary-Text, #242424);
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.form-wrap {
    max-width: 755px;
    width: 95%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}
.name-email{
    gap: 11px;
    width: 100%;
}
.email,
.names {
max-width: 354px;
height: 32px;
width: 100%;
border-radius: 8px;
border: 1px solid #000;
background: #F9F9F9;
color: #727272;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
padding-left: 28px;
}

.subject-dept{
    gap: 11px;
width: 100%;
}
.subject{
    border-radius: 8px;
border: 1px solid #000;
background: #F9F9F9;
display: flex;
max-width: 456px;
width: 100%;
align-items: center;
gap: 8px;
height: 32px;
color: #959595;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
padding-left: 28px;
}
.dept{
    border-radius: 8px;
border: 1px solid #000;
background: #F9F9F9;
display: flex;
max-width: 253px;
width: 100%;
align-items: center;
gap: 150px;
height: 32px;
padding-left: 28px;
}
.message{
    width: 100%;
}
.message textarea{
    resize: none;
    border-radius: 8px;
border: 1px solid #000;
background: #F9F9F9;
display: flex;
max-width: 723px;
width: 100%;
height: 90px;
align-items: flex-start;
gap: 8px;
color: #959595;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
padding-top: 12px;
padding-left: 28px;
}
.file-upload{
    gap: 11px;
    width: 100%;
    justify-content: flex-start;
}
.file-upload input{
    border-radius: 8px;
border: 1px solid #000;
background: #F9F9F9;
display: flex;
max-width: 456px;
width: 100%;
height: 32px;
align-items: center;
gap: 8px;
color: #959595;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
padding-left: 28px;
}
.upload-btn1{
    color: #FFF;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
border-radius: 8px;
background: #242424;
display: flex;
width: 138px;
height: 32px;
justify-content: center;
align-items: center;
gap: 8px;
padding: 0 0;
}
.submit-content h3{
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 110% */
    max-width: 525px;
    width: 95%;
}
.okay{
    display: none;
    color: #FFF;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
border-radius: 8px;
background: #5AB581;
width: 138px;
height: 32px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
}
.custom-drop{
    position: relative;
    width: 100%;
    max-width: 253px;
}
.dropdown-list{
    position: absolute;
    list-style: none;
    width: 100%;
    border: 1px solid #000;
    background: #F9F9F9;
border-radius: 8px;
margin-top: 4px;
padding-left: 0px;

}
.dropli{
    border-bottom: 1px solid #000;
    height: 29px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.droparr{
    position: absolute;
    top: 16px;
    right: 10px;
}
.rotated {
    transform: rotate(180deg); 
    /* transition: transform 0.3s ease; */
  }
@media (max-width:550px) {
    .form-wrap{
        align-items: center;
    }
    .name-email{
        flex-direction: column;
    }
    .subject-dept{
        flex-direction: column;
    }
    .subject{
        max-width: 354px;
    }
    .dept{
        max-width: 354px;
    }
    .message textarea{
        max-width: 354px;
    }
    .file-upload{
        flex-direction: column;
    }
    .file-upload input{
        max-width: 354px;
    }
    .upload-btn1{
        max-width: 354px;
        width: 100%;
    }
    .accept1{
        width: 100%;
        max-width: 354px;
    }
    .help{
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .file-upload input,
    .subject,
    .dept,
    .email,
     .names {
        width: 90%;
    }
    .okay{
        display: flex;
        margin-top: 16px;
    }
    .close-form{
        display: none;
    }
}
