.requests {
    /* max-width: 1280px; */
    width: 100%;
    margin-top: 8px;
}

.request {
    border-bottom: 1px solid #959595;
    background: #FDFDFD;
    /* max-width: 1280px; */
    width: 100%;
    min-height: 95px;
    flex-shrink: 0;
    gap: 80px;
    flex-wrap: wrap;
    transition: height 0.3s ease;
    row-gap: 5px;
position: relative;
}

.name {
    display: flex;
    width: 187px;
    align-items: center;
    gap: 12px;
}

.name h3 {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 100% */
    text-align: left;
}

.Campaignname {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 153.846% */
    max-width: 218px;
    width: 100%;
}

.time-left {
    gap: 7px;
    height: 95px;
}

.left {
    color: #959595;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.time {
    gap: 5.5px;
}

.hour,
.min,
.sec {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 24px;
    height: 17px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #242424;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content-payout {
    gap: 6px;
}

.payout,
.content {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 175px;
    height: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.black {
    color: #242424;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dropdown {
    cursor: pointer;
}

.expanded {
    /* height: 355px; */
}

.additional-content {
    align-items: flex-start;
    gap: 15px;
    margin-bottom: 48px;
    max-width: 1017px;
    width: 95%;
    margin-top: 12px;
}

.additional-content p {
    max-width: 932px;
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    width: 95%;
}

.type-goal {
    gap: 15px;
    flex-wrap: wrap;
}

.type {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 155px;
    height: 24px;
    padding: 0px 9px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: #242424;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.download {
    border-radius: 8px;
    border: 1px solid #5AB581;
    display: flex;
    width: 143px;
    height: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: #5AB581;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.download:hover {
    background-color: #5AB581;
    color: whitesmoke;
}

.accept-div {
    width: 100%;
    justify-content: flex-end;
}

.accept {
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    width: 138px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    cursor: pointer;

}

.request:hover {
    background: #FDFDFD;
}

/* Your existing CSS styles */
/* ... */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.overlay-content {
    border-radius: 8px;
    background: #F9F9F9;
    max-width: 744px;
    width: 95%;
    height: 284px;
    flex-shrink: 0;
    position: relative;
}

.overlay-content h3 {
    max-width: 414px;
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.buttons  {
display: flex;
gap: 8px;
margin-top: 40px;
}

.not-sure {
    color: #407657;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid #407657;
    display: flex;
    width: 138px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    cursor: pointer;
}


.close {
    position: absolute;
    top: 14px;
    right: 15px;
    cursor: pointer;
}
@media (max-width:1010px) {
    .request{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .dropdowncover{
        display: flex;
    }
}
@media (max-width:500px) {
    .additional-content{
align-items: center;
    }
    .additional-content p{
        text-align: center;
    }
    .download-request{
        width: 80%;
    }
    .accept-div{
        justify-content: center;
    }
    .accept{
        width: 80%;
    }
}