.profile1 {
    gap: 22px;
    width: 100%;
    margin-top: 66px;
    margin-bottom: 66px;
}

.basic-content {
    gap: 19px;
    max-width: 1120px;
    width: 100%;
    min-height: 352px;
    flex-wrap: wrap;
}

.basic {
    border-radius: 8px;
    background: #F9F9F9;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    max-width: 661px;
    width: 100%;
    height: 352px;
    flex-shrink: 0;
    justify-content: flex-start;

}

.basic-info {
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #DEDEDE;
    background: #E7E1C3;
    width: 100%;
    height: 55px;
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
    text-transform: uppercase;
}

.profile-change {
    margin-top: 45px;
    position: relative;
}
.pedit{
    position: absolute;
    right: 0;
    top: 6px
}

.dp{
    height: auto;
    width: 100%;

}
.feilds {
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    margin-top: 28px;
    width: 100%;
}

.unchange {
    gap: 8px;
    max-width: 272px;
    width: 100%;
}

.unchange input {
    border-radius: 4px;
    border: 1px solid #000;
    background: #FFF;
    display: flex;
    max-width: 263px;
    width: 95%;
    height: 32px;
    align-items: center;
    gap: 8px;
    color: #3D3C3C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 9px;
}

.change {
    gap: 8px;
    max-width: 272px;
    width: 100%;
}

.change input {
    border-radius: 4px;
    border: 1px solid #000;
    background: #FFF;
    display: flex;
    max-width: 263px;
    width: 95%;
    height: 32px;
    align-items: center;
    gap: 8px;
    color: #3D3C3C;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 9px;
}

.pencil {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pencil img {
    position: absolute;
    right: 8px;
    top: 6px;
    height: 24px;
    width: 24px;
}

.Content {
    justify-content: flex-start;
    border-radius: 8px;
    background: #F9F9F9;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    max-width: 439px;
    width: 100%;
    height: 352px;
}

.Category {
    max-width: 272px;
    width: 100%;
    margin-top: 37px;
    gap: 6px;
    align-items: baseline;

}

.Category h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    text-transform: uppercase;
}

.Category input {
    border-radius: 4px;
    border: 1px solid #000;
    background: #FFF;
    display: flex;
    display: flex;
    max-width: 263px;
    width: 95%;
    height: 32px;
    align-items: center;
    gap: 8px;
    color: #3D3C3C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 9px;
}

.Category p {
    color: var(--Yellow-Text, #B88D00);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 160% */
    margin-left: 4px;
    cursor: pointer;
}

.select-type {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 26px;
}

.select-type h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    text-transform: uppercase;
}

.select-type p {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
    max-width: 275px;
    text-align: left;
}

.pass-security {
    border-radius: 8px;
    background: #FDFDFD;
    max-width: 1119px;
    width: 95%;
    min-height: 128px;
    justify-content: flex-start;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);

}

.general-head {
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #DEDEDE;
    background: #E7E1C3;
    width: 100%;
    height: 55px;
    justify-content: flex-start;
}

.general-head h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    text-transform: uppercase;
    display: flex;
    gap: 8px;
    align-items: center;
    margin-left: 49px;
}

.para-drop {
    justify-content: space-between;
    width: 100%;
    height: 73px;
}

.para-drop p {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
    max-width: 275px;
    margin-left: 30px;
    text-align: left;
}

.para-drop img {
    margin-right: 30px;
    cursor: pointer;
}

.greens {
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    width: 138px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    cursor: pointer;
    border: none;
}
.error-wrap{
    position: relative;


}
.berror{
    position: absolute;
    color: red;
    display: flex;
    font-size: 13px;

}
.uerror{
    color: red;
    display: flex;
    font-size: 13px;
    position: absolute;
    bottom: -19px;
    left: 0;
}
.passwords {
    gap: 16px;
    margin-top: 48px;
    margin-bottom: 40px;
width: 100%;
flex-wrap: wrap;
}

.passwords input {
    color: #3D3C3C;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid #000;
    background: #FFF;
    display: flex;
    max-width: 263px;
    width: 100%;
    height: 32px;
    align-items: center;
    gap: 101px;
    flex-shrink: 0;
    padding-left: 9px;
}

.balance-bank {
    gap: 33px;
    width: 100%;
    margin-top: 38px;
    flex-wrap: wrap;
}

.balance {
    border-radius: 8px;
    background: #F9F9F9;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    max-width: 478px;
    width: 100%;
    height: 218px;
    flex-shrink: 0;
}

.balance-left {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 80% */
}

.gold {
    color: var(--Yellow-Text, #B88D00);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

.balance-cover {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
}

.balance-withdraw {
    gap: 9px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.balance-withdraw input {
    color: #959595;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid #000;
    background: #FEFEFE;
    display: flex;
    width: 263px;
    height: 32px;
    align-items: center;
    gap: 8px;
    padding-left: 9px;
}

.withdraw p {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
    display: flex;
}

.bank {
    border-radius: 8px;
    background: #F9F9F9;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    max-width: 478px;
    width: 100%;
    height: 218px;
    flex-shrink: 0;
}

.account-detail {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 100% */
    max-width: 351px;
    width: 100%;
    display: flex;
    margin-bottom: 22px;
}

.all-details {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 74%;
}

.all-details h3 {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.bold {
    color: #242424;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 183.333% */
    text-transform: uppercase;
}

.change-div {
    width: 90%;
    justify-content: flex-end;
}

.update-bank {
    border-radius: 8px;
    background: #F9F9F9;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    max-width: 988px;
    width: 95%;
    min-height: 218px;
    flex-shrink: 0;
    margin-top: 38px;
}

.detail-update {
    width: 90%;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 33px;
}

.detail-update h3 {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 100% */
}

.bank-feild {
    gap: 17px;
    flex-wrap: wrap;
}

.bank-feild input {
    color: #959595;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid #000;
    background: #FEFEFE;
    display: flex;
    width: 263px;
    height: 32px;
    align-items: center;
    gap: 8px;
    padding-left: 9px;
}

.one,
.two,
.three {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.upi {
    border-radius: 8px;
    background: #F9F9F9;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    max-width: 989px;
    width: 95%;
    min-height: 81px;
    flex-shrink: 0;
    margin-top: 28px;
    justify-content: space-evenly;

}

.upiid {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 100% */

}

.upi-input {
    gap: 12px;
    position: relative;
}

.upi-input input {
    color: #959595;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid #000;
    background: #FEFEFE;
    display: flex;
    width: 263px;
    height: 32px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    padding-left: 9px;
}

.withdraw-history {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 54px;
    width: 992px;
    /* width: 100%; */
    
}
.history-cover{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 54px;
    max-width: 992px;
    width: 95%;
    overflow-x: scroll;
    align-items: flex-start;

}

.history-head {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 100% */
    margin-bottom: 14px;

}

.hhead {
    background: rgba(238, 238, 238, 0.93);
    max-width: 992px;
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    justify-content: flex-start;
    gap: 65px;
}

.hhead h3 {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 100% */
}

.transactions {
    background: #F9F9F9;
    max-width: 992px;
    width: 100%;
    height: 192px;
    flex-shrink: 0;
    margin-bottom: 71px;
    overflow-y: scroll;
    display: block;

}

.notrans h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    text-transform: uppercase;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trans {
    width: 100%;
    justify-content: space-around;
    height: 48px;
}

.accountno {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
}

.amount {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
    max-width: 50px;
    width: 100%;
}

.transactiondate {
    max-width: 95px;
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
    width: 100%;
}

.transactionstatus {
    max-width: 265px;
    width: 100%;
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
}

.change-category h3 {
    max-width: 414px;
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.thank-change h3 {
    max-width: 520px;
    color: #000;
    text-align: center;

    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 110% */
}

.withdrawal-popup h3 {
    max-width: 276px;
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 36px;
}

.woption {
    border-radius: 4px;
    border: 1px solid #000;
    background: #FEFEFE;
    display: flex;
    max-width: 156px;
    width: 100%;
    height: 32px;

    align-items: center;
    gap: 8px;
}

.withdrawal-options {
    width: 100%;
    gap: 16px;
}

.otp-input h3 {
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 414px;
    margin-bottom: 30px;
}

.contents {
    margin-top: 12px;
    gap: 6px;
}

.addd {
    border-radius: 5.647px;
    background: #FFF;
    box-shadow: 0px 0.70588px 2.82353px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 25.412px;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
}

.options {
    gap: 6px;
    flex-wrap: wrap;

}

.option-list {
    gap: 6px;

}

.option-list div {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 71px;
    height: 24px;
    align-items: center;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #242424;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 8px;
    cursor: pointer;
}

.option-list h3 {
    color: #242424;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
    width: 34px;
}

.option-list img {
    height: 16px;
    width: 16px;
}

.save {
    color: #FFF;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 4px;
    background: #5AB581;
    border-radius: 4px;
    width: 63px;
    height: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.selected-options {
    gap: 6px;
}

.selected-options div {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 51px;
    height: 24px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #242424;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.update2{
    display: none;
}
.greens3{
    display: none;
}
.category-select{

}
.profileWrapper{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
@media (max-width:1120px) {
    .basic-content{
        flex-direction: column;
    }
    .category-select{
        display: flex;
        gap: 24px;
    }
    .Content{
        height: auto;
        padding-bottom: 24px;
    }
    .basic{
        max-width: 1120px;
        width: 95%;
    }
    .Content{
        max-width: 1120px;
        width: 95%;
    }
    .balance-bank{
        flex-direction: column;
    }
    .balance{
        max-width: 1120px;
        width: 95%;
    }
    .bank{
        max-width: 1120px;
        width: 95%;  
    }
    .account-detail{
        max-width: 1200px;
        width: 74%;
    }
}

@media (max-width:953px) {
.three{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

}
  .update-bank{
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
@media (max-width:950px) {
   .passwords{
    flex-direction: column;
   }
   .passwords input{
    width: 95%;
   }
}
@media (max-width:670px) {
    .bank-feild{
        flex-direction: column;
    }
    .three{
        flex-direction: column;
    }
    .update1{
        display: none;
    }
    .update2{
        display: flex;
        margin-top: 24px;
    }
    .upi{
        margin-top: 20px;
        flex-direction: column;
        gap: 8px;
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .upi-input{
        flex-direction: column;
        gap: 8px;
        margin-bottom: 20px;

    }
    .change-div{
        justify-content: center;
        margin-top: 27px;
        margin-bottom: 20px;
    }
    .bank{
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .balance-left{
        color: var(--Main-Text, #242424);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 100% */
    }
    .gold{
        color: var(--Yellow-Text, #B88D00);
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 16px;
    }
    .balance-withdraw input{
        max-width: 263px;
        width: 95%;
    }
    .greensmall{
        border-radius: 8px;
background: #5AB581;
display: flex;
width: 102px;
height: 32px;
justify-content: center;
align-items: center;
gap: 8px;
    }
}
@media (max-width:610px) {
    .feilds{
        flex-direction: column;
    }
    .basic{
        height: auto;
        padding-bottom: 20px;
    }
    .greensmall{
        display: none;
    }
    .greens3{
        display: flex;
    }
    .balance-cover{
        align-items: center;

    }
    .all-details{
        width: 95%;
    }
    .category-select{
        flex-direction: column;
    }
}
@media (max-width:350px) {
    .upi-input input,
    .bank-feild input {
        max-width: 263px;
        width: 95%;
    }
    .error-wrap{
        width: 100%;
    }

}